import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	ColorBlack,
	ColorGrayDark,
	ColorGrayLighter,
	ColorGreenDarker,
	ColorGreenLighter,
	ColorOrangeDark,
	ColorWhite,
	ColorYellowLighter,
} from "@siteimprove/fancylib/design-tokens/variables";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { TextHighlight } from "./text-highlight";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Text Highlight",
	notepad: "https://hackmd.io/jNzr-8Q4SgCFM52WVOOGjQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Text Highlight"
			subTitle="Visually emphasizes specific words or phrases within a body of text."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={TextHighlight} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The <Code>TextHighlight</Code> component visually emphasizes specific words or phrases
					within a body of text by applying bold styling and a background color, guiding the user's
					attention and enhancing comprehension.
				</Paragraph>
			</TextContainer>
			<Example fn={BasicUsage} />
			<TextContainer article>
				<Header.H3>Case Sensitive</Header.H3>
				<Paragraph>
					The component is case-insensitive, so the effect will be the same whether you use{" "}
					<Code>highlight</Code>, <Code>HIGHLIGHT</Code>, or <Code>inhighlighting</Code>.
				</Paragraph>
			</TextContainer>
			<Example fn={CaseSensitiveExample} />
			<TextContainer article>
				<Header.H3>Color Theme</Header.H3>
				<Paragraph>
					Text Highlight adapts to both light and dark themes, ensuring visual contrast and
					readability in different contexts.
				</Paragraph>
			</TextContainer>
			<Example fn={ColorThemeExamples} />
			<Header.H2>Properties</Header.H2>
			<Knobs
				component={TextHighlight}
				initialProps={{ needle: "", value: "Is it highlight or HIGHLIGHT?" }}
			/>
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>TextHighlight</Code> to
					</Paragraph>
					<Ul
						items={[
							"Draw attention to key terms, phrases, or data points.",
							"Guide the user's focus in instructional content or tutorials.",
							"Highlight matched search terms or filter results.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>TextHighlight</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lib/components/Text/Paragraph">
									<b>Paragraph</b>
								</GatsbyLink>
								:To emphasize key information within longer blocks of text, such as issue
								descriptions or summaries.
							</>,
							<>
								<b>Search Results</b>: To highlight matching terms in search results, improving
								scannability and guiding the user's attention (e.g{" "}
								<GatsbyLink to="/lab/components/Navigation/Search%20Navigation">
									Search Navigation
								</GatsbyLink>
								).
							</>,
							<>
								<b>Forms and Instructions</b>: To accentuate important details or requirements
								within forms or instructional text.{" "}
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>TextHighlight</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Use Sparingly</b>: Overusing highlights can diminish their effectiveness. Reserve
								them for truly important content.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The highlighted text is long or complex, as it might become difficult to parse.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between the highlight and background for readability.",
							,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Ensure the highlighted text is relevant and meaningful in the context of the surrounding content.",
							,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	return (
		<TextHighlight value="Is it highlight or HIGHLIGHT or inhighlighting?" needle="highlight" />
	);
};

const CaseSensitiveExample = () => {
	return (
		<TextHighlight
			value="Is it highlight or HIGHLIGHT or inhighlighting?"
			needle="highlight"
			caseSensitive
		/>
	);
};

function ColorThemeExample(props: { backgroundColor: string; color: string; children: ReactNode }) {
	const { backgroundColor, color, children } = props;
	return (
		<div
			style={{
				backgroundColor,
				color,
				padding: "5px 10px",
			}}
		>
			{children}
		</div>
	);
}

const ColorThemeExamples = () => {
	return (
		<>
			<ColorThemeExample backgroundColor="#000026" color={ColorWhite}>
				<TextHighlight
					value="You can have light or dark theme, but not bedarkened"
					needle="dark"
					colorTheme="dark"
				/>
			</ColorThemeExample>
			<ColorThemeExample backgroundColor={ColorGrayDark} color={ColorGrayLighter}>
				<TextHighlight
					value="You can have light or dark theme, but not bedarkened"
					needle="dark"
					colorTheme="dark"
				/>
			</ColorThemeExample>
			<ColorThemeExample backgroundColor={ColorGreenDarker} color={ColorGreenLighter}>
				<TextHighlight
					value="You can have light or dark theme, but not bedarkened"
					needle="dark"
					colorTheme="dark"
				/>
			</ColorThemeExample>
			<ColorThemeExample backgroundColor={ColorWhite} color={ColorBlack}>
				<TextHighlight value="The light theme is the default" needle="light" />
			</ColorThemeExample>
			<ColorThemeExample backgroundColor={ColorYellowLighter} color={ColorOrangeDark}>
				<TextHighlight value="Check the contrast when used on custom colors" needle="contrast" />
			</ColorThemeExample>
		</>
	);
};
